/* eslint-disable @typescript-eslint/no-unused-vars */
import { serializeTokens } from 'utils/serializeTokens'
import { bscTokens } from './tokens'
import { SerializedFarmConfig } from './types'

const serializedTokens = serializeTokens(bscTokens)
export const CAKE_BNB_LP_MAINNET = '0x4e56E964F46092012e96B779952e522FC70F2bff'

const farms: SerializedFarmConfig[] = [
  /**
   * These 3 farms (PID 0, 2, 3) should always be at the top of the file.
   */
  {
    pid: 0,
    lpSymbol: 'FTHR',
    lpAddresses: {
      719: '0x19eA65E3f8fc8F61743d137B5107172f849d8Eb3',
      19: CAKE_BNB_LP_MAINNET,
    },
    token: serializedTokens.cake,
    quoteToken: serializedTokens.exusdt,
    isTokenOnly: true,
  },
  {
    pid: 1,
    lpSymbol: 'FTHR-exUSDT LP',
    lpAddresses: {
      719: '0x19eA65E3f8fc8F61743d137B5107172f849d8Eb3',
      19: '0x31e18DD3AD1De29CB8A13Ba434FfDaE28a1517a9',
    },
    token: serializedTokens.cake,
    quoteToken: serializedTokens.exusdt,
  },
  {
    pid: 2,
    lpSymbol: 'FTHR-SGB LP',
    lpAddresses: {
      719: '0xF7a7326F25Afb7da7b84D426B2e7824D326CDa2C',
      19: '0x4e56E964F46092012e96B779952e522FC70F2bff',
    },
    token: serializedTokens.cake,
    quoteToken: serializedTokens.wbnb,
  },
  {
    pid: 3,
    lpSymbol: 'FTHR-sPHX LP',
    lpAddresses: {
      719: '0xF7a7326F25Afb7da7b84D426B2e7824D326CDa2C',
      19: '0x7934d5d590d1141e8fF66B9125A74a68C7b5179e',
    },
    token: serializedTokens.cake,
    quoteToken: serializedTokens.sphx,
  },
  {
    pid: 4,
    lpSymbol: 'FTHR-XAC LP',
    lpAddresses: {
      719: '0xF7a7326F25Afb7da7b84D426B2e7824D326CDa2C',
      19: '0x756dc3172283646303544c9D9FEF2a02a7Fdb402',
    },
    token: serializedTokens.cake,
    quoteToken: serializedTokens.xac,
  },
  {
    pid: 5,
    lpSymbol: 'FTHR-BBX LP',
    lpAddresses: {
      719: '0xF7a7326F25Afb7da7b84D426B2e7824D326CDa2C',
      19: '0xFc91C7Ff8623FCf283857968BD466Bd8089Cb25b',
    },
    token: serializedTokens.cake,
    quoteToken: serializedTokens.bbx,
  },
  {
    pid: 6,
    lpSymbol: 'sPHX-SGB LP',
    lpAddresses: {
      719: '0xF7a7326F25Afb7da7b84D426B2e7824D326CDa2C',
      19: '0x05a2891806d428f7425Ab97478f135E323A9cafD',
    },
    token: serializedTokens.sphx,
    quoteToken: serializedTokens.wbnb,
  },
  {
    pid: 7,
    lpSymbol: 'sPHX-exUSDT LP',
    lpAddresses: {
      719: '0xF7a7326F25Afb7da7b84D426B2e7824D326CDa2C',
      19: '0x4F6e45956ae19166C44d3dDE625130f558Fb8AC5',
    },
    token: serializedTokens.sphx,
    quoteToken: serializedTokens.exusdt,
  },
  {
    pid: 8,
    lpSymbol: 'sPHX-XAC LP',
    lpAddresses: {
      719: '0xF7a7326F25Afb7da7b84D426B2e7824D326CDa2C',
      19: '0x778BB875c6E87A5C9ef907963f98b3aBEDc9E0Aa',
    },
    token: serializedTokens.xac,
    quoteToken: serializedTokens.sphx,
  },
  {
    pid: 9,
    lpSymbol: 'sPHX-BBX LP',
    lpAddresses: {
      719: '0xF7a7326F25Afb7da7b84D426B2e7824D326CDa2C',
      19: '0xbC41286c6E3B81541d9a32E499b980B73019573e',
    },
    token: serializedTokens.bbx,
    quoteToken: serializedTokens.sphx,
  },
  {
    pid: 10,
    lpSymbol: 'exUSDT-SGB LP',
    lpAddresses: {
      719: '0xF7a7326F25Afb7da7b84D426B2e7824D326CDa2C',
      19: '0xFB0eF71a4F5519F47D0bBE25B8A4BB8b706904FB',
    },
    token: serializedTokens.exusdt,
    quoteToken: serializedTokens.wbnb,
  },
  {
    pid: 11,
    lpSymbol: 'XAC-SGB LP',
    lpAddresses: {
      719: '0xF7a7326F25Afb7da7b84D426B2e7824D326CDa2C',
      19: '0x9CBC1Cc3b29D8a61B1843DF50B6e90261A692705',
    },
    token: serializedTokens.xac,
    quoteToken: serializedTokens.wbnb,
  },
  {
    pid: 12,
    lpSymbol: 'BBX-SGB LP',
    lpAddresses: {
      719: '0xF7a7326F25Afb7da7b84D426B2e7824D326CDa2C',
      19: '0x1775a544204a8bedb846e3e370cb2e6ca894c4d8',
    },
    token: serializedTokens.bbx,
    quoteToken: serializedTokens.wbnb,
  },
  {
    pid: 13,
    lpSymbol: 'XAC-exUSDT LP',
    lpAddresses: {
      719: '0xF7a7326F25Afb7da7b84D426B2e7824D326CDa2C',
      19: '0x3CaE4dC68AC0279d992bEC71c9db9387e43B3358',
    },
    token: serializedTokens.xac,
    quoteToken: serializedTokens.exusdt,
  },
  {
    pid: 14,
    lpSymbol: 'BBX-exUSDT LP',
    lpAddresses: {
      719: '0xF7a7326F25Afb7da7b84D426B2e7824D326CDa2C',
      19: '0xa23E6DD2D6c81a558A1f451255AC0c24e9d137F2',
    },
    token: serializedTokens.bbx,
    quoteToken: serializedTokens.exusdt,
  },
  {
    pid: 15,
    lpSymbol: 'BBX-XAC LP',
    lpAddresses: {
      719: '0xF7a7326F25Afb7da7b84D426B2e7824D326CDa2C',
      19: '0x74137E21701Ce25Eb2eebA32dfa0fc90Ce72Fb29',
    },
    token: serializedTokens.bbx,
    quoteToken: serializedTokens.xac,
  },
  {
    pid: 16,
    lpSymbol: 'sPHX-SFIN LP',
    lpAddresses: {
      719: '0xF7a7326F25Afb7da7b84D426B2e7824D326CDa2C',
      19: '0x7a8B9fA6cb4eb2E6b1dCdB34FD2f7e6243AFd22E',
    },
    token: serializedTokens.sphx,
    quoteToken: serializedTokens.sfin,
  },
  {
    pid: 17,
    lpSymbol: 'FTHR-SFIN LP',
    lpAddresses: {
      719: '0xF7a7326F25Afb7da7b84D426B2e7824D326CDa2C',
      19: '0xde4de3f27fC79e2934749b3695f9d5B7EF35eF56',
    },
    token: serializedTokens.cake,
    quoteToken: serializedTokens.sfin,
  },
  {
    pid: 18,
    lpSymbol: 'SFIN-SGB LP',
    lpAddresses: {
      719: '0xF7a7326F25Afb7da7b84D426B2e7824D326CDa2C',
      19: '0x8733F3aD92Bed0a036988dBA141fc9596576a07c',
    },
    token: serializedTokens.sfin,
    quoteToken: serializedTokens.wbnb,
  },
  {
    pid: 19,
    lpSymbol: 'SFIN-exUSDT LP',
    lpAddresses: {
      719: '0xF7a7326F25Afb7da7b84D426B2e7824D326CDa2C',
      19: '0x5af5f71B1f1C9c33Cb13FDc29B612F3a4ab0f416',
    },
    token: serializedTokens.sfin,
    quoteToken: serializedTokens.exusdt,
  },
  {
    pid: 20,
    lpSymbol: 'SFIN-XAC LP',
    lpAddresses: {
      719: '0xF7a7326F25Afb7da7b84D426B2e7824D326CDa2C',
      19: '0xd60cD3F717e3C968Cc6BB2A702C7c956be9aE9A2',
    },
    token: serializedTokens.sfin,
    quoteToken: serializedTokens.xac,
  },
  {
    pid: 21,
    lpSymbol: 'SFIN-BBX LP',
    lpAddresses: {
      719: '0xF7a7326F25Afb7da7b84D426B2e7824D326CDa2C',
      19: '0x7Ca7D9Fc481a34AE5A0757658f5bF3282Bc2a784',
    },
    token: serializedTokens.sfin,
    quoteToken: serializedTokens.bbx,
  },
  {
    pid: 22,
    lpSymbol: 'SFIN-PSB LP',
    lpAddresses: {
      719: '0xF7a7326F25Afb7da7b84D426B2e7824D326CDa2C',
      19: '0x627075132304e178a8fDDEfe3ba244867bfaFbd3',
    },
    token: serializedTokens.sfin,
    quoteToken: serializedTokens.psb,
  },
  {
    pid: 23,
    lpSymbol: 'SFIN-PRO LP',
    lpAddresses: {
      719: '0xF7a7326F25Afb7da7b84D426B2e7824D326CDa2C',
      19: '0x792F19801F2BaF7140DcFd932f8496975dD0EbDB',
    },
    token: serializedTokens.sfin,
    quoteToken: serializedTokens.pro,
  },
  {
    pid: 24,
    lpSymbol: 'sPHX-PSB LP',
    lpAddresses: {
      719: '0xF7a7326F25Afb7da7b84D426B2e7824D326CDa2C',
      19: '0x63b7707f7e3Cf1866203CB19e8f7A65dC732e487',
    },
    token: serializedTokens.sphx,
    quoteToken: serializedTokens.psb,
  },
  {
    pid: 25,
    lpSymbol: 'FTHR-PSB LP',
    lpAddresses: {
      719: '0xF7a7326F25Afb7da7b84D426B2e7824D326CDa2C',
      19: '0xC201ce82680F8e141942Dfc8DB60250Ca3aE4e90',
    },
    token: serializedTokens.cake,
    quoteToken: serializedTokens.psb,
  },
  {
    pid: 26,
    lpSymbol: 'PSB-SGB LP',
    lpAddresses: {
      719: '0xF7a7326F25Afb7da7b84D426B2e7824D326CDa2C',
      19: '0x38B20272c1b165b050747844f6B541CC27Cf9EB7',
    },
    token: serializedTokens.psb,
    quoteToken: serializedTokens.wbnb,
  },
  {
    pid: 27,
    lpSymbol: 'PSB-exUSDT LP',
    lpAddresses: {
      719: '0xF7a7326F25Afb7da7b84D426B2e7824D326CDa2C',
      19: '0x4049676a220eaE896d1F3C61b8118B63268Afb34',
    },
    token: serializedTokens.psb,
    quoteToken: serializedTokens.exusdt,
  },
  {
    pid: 28,
    lpSymbol: 'PSB-XAC LP',
    lpAddresses: {
      719: '0xF7a7326F25Afb7da7b84D426B2e7824D326CDa2C',
      19: '0xfd37246deD6292A6C3280a66f5FF268FE7d3510f',
    },
    token: serializedTokens.psb,
    quoteToken: serializedTokens.xac,
  },
  {
    pid: 29,
    lpSymbol: 'PSB-BBX LP',
    lpAddresses: {
      719: '0xF7a7326F25Afb7da7b84D426B2e7824D326CDa2C',
      19: '0xb3dA7174477407efCB53C7d5aBEb0d71bCE4e50d',
    },
    token: serializedTokens.psb,
    quoteToken: serializedTokens.bbx,
  },
  {
    pid: 30,
    lpSymbol: 'PSB-PRO LP',
    lpAddresses: {
      719: '0xF7a7326F25Afb7da7b84D426B2e7824D326CDa2C',
      19: '0x1572115B3Af953e95a8083CA967aCCD9d23eEf16',
    },
    token: serializedTokens.psb,
    quoteToken: serializedTokens.pro,
  },
  {
    pid: 31,
    lpSymbol: 'sPHX-PRO LP',
    lpAddresses: {
      719: '0xF7a7326F25Afb7da7b84D426B2e7824D326CDa2C',
      19: '0xC0DdFdD822fEB5e02E73E8be5B9059A17778c84d',
    },
    token: serializedTokens.sphx,
    quoteToken: serializedTokens.pro,
  },
  {
    pid: 32,
    lpSymbol: 'FTHR-PRO LP',
    lpAddresses: {
      719: '0xF7a7326F25Afb7da7b84D426B2e7824D326CDa2C',
      19: '0xe78caeB7fe6e3469777eEF820FD1551C6A7405ab',
    },
    token: serializedTokens.cake,
    quoteToken: serializedTokens.pro,
  },
  {
    pid: 33,
    lpSymbol: 'PRO-SGB LP',
    lpAddresses: {
      719: '0xF7a7326F25Afb7da7b84D426B2e7824D326CDa2C',
      19: '0xeF4C573d4dbf890327eD16355130ed0f159e5747',
    },
    token: serializedTokens.pro,
    quoteToken: serializedTokens.wbnb,
  },
  {
    pid: 34,
    lpSymbol: 'PRO-exUSDT LP',
    lpAddresses: {
      719: '0xF7a7326F25Afb7da7b84D426B2e7824D326CDa2C',
      19: '0x47B25f879B3a9594F87E820f60C2E47d0a3e91B4',
    },
    token: serializedTokens.pro,
    quoteToken: serializedTokens.exusdt,
  },
  {
    pid: 35,
    lpSymbol: 'PRO-XAC LP',
    lpAddresses: {
      719: '0xF7a7326F25Afb7da7b84D426B2e7824D326CDa2C',
      19: '0x265f12ECfd6c278Eb13b31a9Fbc6E0774a5f766B',
    },
    token: serializedTokens.pro,
    quoteToken: serializedTokens.xac,
  },
  {
    pid: 36,
    lpSymbol: 'PRO-BBX LP',
    lpAddresses: {
      719: '0xF7a7326F25Afb7da7b84D426B2e7824D326CDa2C',
      19: '0x7DD6aa9e4bB9271C50cF4d32Eb4149ce2484cbEA',
    },
    token: serializedTokens.pro,
    quoteToken: serializedTokens.bbx,
  },
  // {
  //   pid: 37,
  //   lpSymbol: 'NISHI-sPHX LP',
  //   lpAddresses: {
  //     719: '0xF7a7326F25Afb7da7b84D426B2e7824D326CDa2C',
  //     19: '0x8AcE082de6da785ea037DeCD677E441b590c12d2',
  //   },
  //   token: serializedTokens.nishi,
  //   quoteToken: serializedTokens.sphx,
  // },
  // {
  //   pid: 38,
  //   lpSymbol: 'FTHR-NISHI LP',
  //   lpAddresses: {
  //     719: '0xF7a7326F25Afb7da7b84D426B2e7824D326CDa2C',
  //     19: '0xDE33D873cC7DdfabC82D3B8c6c7d4ECD9bD43789',
  //   },
  //   token: serializedTokens.nishi,
  //   quoteToken: serializedTokens.cake,
  // },
  // {
  //   pid: 39,
  //   lpSymbol: 'SGB-NISHI LP',
  //   lpAddresses: {
  //     719: '0xF7a7326F25Afb7da7b84D426B2e7824D326CDa2C',
  //     19: '0x88a7d8f2fcad804022724f9f6929fac5bc5126c4',
  //   },
  //   token: serializedTokens.wbnb,
  //   quoteToken: serializedTokens.nishi,
  // },
  // {
  //   pid: 40,
  //   lpSymbol: 'XAC-NISHI LP',
  //   lpAddresses: {
  //     719: '0xF7a7326F25Afb7da7b84D426B2e7824D326CDa2C',
  //     19: '0xa4a929bfd9b3c6b4c4d0833f2eb52831145f10bd',
  //   },
  //   token: serializedTokens.xac,
  //   quoteToken: serializedTokens.nishi,
  // },
  // {
  //   pid: 41,
  //   lpSymbol: 'BBX-NISHI LP',
  //   lpAddresses: {
  //     719: '0xF7a7326F25Afb7da7b84D426B2e7824D326CDa2C',
  //     19: '0x0a838fc0576c7cef1d2c2cf30472aaee710c396e',
  //   },
  //   token: serializedTokens.bbx,
  //   quoteToken: serializedTokens.nishi,
  // },
  // {
  //   pid: 42,
  //   lpSymbol: 'exUSDT-NISHI LP',
  //   lpAddresses: {
  //     719: '0xF7a7326F25Afb7da7b84D426B2e7824D326CDa2C',
  //     19: '0x07a1239b6989a0995f692d061c91f4398f5e3cfd',
  //   },
  //   token: serializedTokens.exusdt,
  //   quoteToken: serializedTokens.nishi,
  // },
  // {
  //   pid: 43,
  //   lpSymbol: 'SFIN-NISHI LP',
  //   lpAddresses: {
  //     719: '0xF7a7326F25Afb7da7b84D426B2e7824D326CDa2C',
  //     19: '0x3f836EE77119276708896D7922379788d90D0Bcb',
  //   },
  //   token: serializedTokens.sfin,
  //   quoteToken: serializedTokens.nishi,
  // },
  // {
  //   pid: 44,
  //   lpSymbol: 'PRO-NISHI LP',
  //   lpAddresses: {
  //     719: '0xF7a7326F25Afb7da7b84D426B2e7824D326CDa2C',
  //     19: '0x07a1239b6989a0995f692d061c91f4398f5e3cfd',
  //   },
  //   token: serializedTokens.pro,
  //   quoteToken: serializedTokens.nishi,
  // },
  // {
  //   pid: 45,
  //   lpSymbol: 'PSB-NISHI LP',
  //   lpAddresses: {
  //     719: '0xF7a7326F25Afb7da7b84D426B2e7824D326CDa2C',
  //     19: '0x07a1239b6989a0995f692d061c91f4398f5e3cfd',
  //   },
  //   token: serializedTokens.psb,
  //   quoteToken: serializedTokens.nishi,
  // },
  // {
  //   pid: 46,
  //   lpSymbol: 'SPRK-NISHI LP',
  //   lpAddresses: {
  //     719: '0xF7a7326F25Afb7da7b84D426B2e7824D326CDa2C',
  //     19: '0x07a1239b6989a0995f692d061c91f4398f5e3cfd',
  //   },
  //   token: serializedTokens.sprk,
  //   quoteToken: serializedTokens.nishi,
  // },
  // {
  //   pid: 47,
  //   lpSymbol: 'CHIRP-NISHI LP',
  //   lpAddresses: {
  //     719: '0xF7a7326F25Afb7da7b84D426B2e7824D326CDa2C',
  //     19: '0x07a1239b6989a0995f692d061c91f4398f5e3cfd',
  //   },
  //   token: serializedTokens.chirp,
  //   quoteToken: serializedTokens.nishi,
  // },
  // {
  //   pid: 48,
  //   lpSymbol: 'FTHR-SPRK LP',
  //   lpAddresses: {
  //     719: '0xF7a7326F25Afb7da7b84D426B2e7824D326CDa2C',
  //     19: '0xDE33D873cC7DdfabC82D3B8c6c7d4ECD9bD43789',
  //   },
  //   token: serializedTokens.cake,
  //   quoteToken: serializedTokens.sprk,
  // },
  // {
  //   pid: 49,
  //   lpSymbol: 'sPHX-SPRK LP',
  //   lpAddresses: {
  //     719: '0xF7a7326F25Afb7da7b84D426B2e7824D326CDa2C',
  //     19: '0x8AcE082de6da785ea037DeCD677E441b590c12d2',
  //   },
  //   token: serializedTokens.sphx,
  //   quoteToken: serializedTokens.sprk,
  // },
  // {
  //   pid: 50,
  //   lpSymbol: 'SGB-SPRK LP',
  //   lpAddresses: {
  //     719: '0xF7a7326F25Afb7da7b84D426B2e7824D326CDa2C',
  //     19: '0x88a7d8f2fcad804022724f9f6929fac5bc5126c4',
  //   },
  //   token: serializedTokens.wbnb,
  //   quoteToken: serializedTokens.sprk,
  // },
  // {
  //   pid: 51,
  //   lpSymbol: 'XAC-SPRK LP',
  //   lpAddresses: {
  //     719: '0xF7a7326F25Afb7da7b84D426B2e7824D326CDa2C',
  //     19: '0xa4a929bfd9b3c6b4c4d0833f2eb52831145f10bd',
  //   },
  //   token: serializedTokens.xac,
  //   quoteToken: serializedTokens.sprk,
  // },
  // {
  //   pid: 52,
  //   lpSymbol: 'BBX-SPRK LP',
  //   lpAddresses: {
  //     719: '0xF7a7326F25Afb7da7b84D426B2e7824D326CDa2C',
  //     19: '0x0a838fc0576c7cef1d2c2cf30472aaee710c396e',
  //   },
  //   token: serializedTokens.bbx,
  //   quoteToken: serializedTokens.sprk,
  // },
  // {
  //   pid: 53,
  //   lpSymbol: 'exUSDT-SPRK LP',
  //   lpAddresses: {
  //     719: '0xF7a7326F25Afb7da7b84D426B2e7824D326CDa2C',
  //     19: '0x07a1239b6989a0995f692d061c91f4398f5e3cfd',
  //   },
  //   token: serializedTokens.exusdt,
  //   quoteToken: serializedTokens.sprk,
  // },
  // {
  //   pid: 54,
  //   lpSymbol: 'SFIN-SPRK LP',
  //   lpAddresses: {
  //     719: '0xF7a7326F25Afb7da7b84D426B2e7824D326CDa2C',
  //     19: '0x07a1239b6989a0995f692d061c91f4398f5e3cfd',
  //   },
  //   token: serializedTokens.sfin,
  //   quoteToken: serializedTokens.sprk,
  // },
  // {
  //   pid: 55,
  //   lpSymbol: 'PRO-SPRK LP',
  //   lpAddresses: {
  //     719: '0xF7a7326F25Afb7da7b84D426B2e7824D326CDa2C',
  //     19: '0x07a1239b6989a0995f692d061c91f4398f5e3cfd',
  //   },
  //   token: serializedTokens.pro,
  //   quoteToken: serializedTokens.sprk,
  // },
  // {
  //   pid: 56,
  //   lpSymbol: 'PSB-SPRK LP',
  //   lpAddresses: {
  //     719: '0xF7a7326F25Afb7da7b84D426B2e7824D326CDa2C',
  //     19: '0x07a1239b6989a0995f692d061c91f4398f5e3cfd',
  //   },
  //   token: serializedTokens.psb,
  //   quoteToken: serializedTokens.sprk,
  // },
  // {
  //   pid: 57,
  //   lpSymbol: 'CHIRP-SPRK LP',
  //   lpAddresses: {
  //     719: '0xF7a7326F25Afb7da7b84D426B2e7824D326CDa2C',
  //     19: '0x07a1239b6989a0995f692d061c91f4398f5e3cfd',
  //   },
  //   token: serializedTokens.chirp,
  //   quoteToken: serializedTokens.sprk,
  // },
  // {
  //   pid: 58,
  //   lpSymbol: 'FTHR-CHIRP LP',
  //   lpAddresses: {
  //     719: '0xF7a7326F25Afb7da7b84D426B2e7824D326CDa2C',
  //     19: '0xDE33D873cC7DdfabC82D3B8c6c7d4ECD9bD43789',
  //   },
  //   token: serializedTokens.cake,
  //   quoteToken: serializedTokens.chirp,
  // },
  // {
  //   pid: 59,
  //   lpSymbol: 'sPHX-CHIRP LP',
  //   lpAddresses: {
  //     719: '0xF7a7326F25Afb7da7b84D426B2e7824D326CDa2C',
  //     19: '0x88a7d8f2fcad804022724f9f6929fac5bc5126c4',
  //   },
  //   token: serializedTokens.sphx,
  //   quoteToken: serializedTokens.chirp,
  // },
  // {
  //   pid: 60,
  //   lpSymbol: 'SGB-CHIRP LP',
  //   lpAddresses: {
  //     719: '0xF7a7326F25Afb7da7b84D426B2e7824D326CDa2C',
  //     19: '0x88a7d8f2fcad804022724f9f6929fac5bc5126c4',
  //   },
  //   token: serializedTokens.wbnb,
  //   quoteToken: serializedTokens.chirp,
  // },
  // {
  //   pid: 61,
  //   lpSymbol: 'XAC-CHIRP LP',
  //   lpAddresses: {
  //     719: '0xF7a7326F25Afb7da7b84D426B2e7824D326CDa2C',
  //     19: '0xa4a929bfd9b3c6b4c4d0833f2eb52831145f10bd',
  //   },
  //   token: serializedTokens.xac,
  //   quoteToken: serializedTokens.chirp,
  // },
  // {
  //   pid: 62,
  //   lpSymbol: 'BBX-CHIRP LP',
  //   lpAddresses: {
  //     719: '0xF7a7326F25Afb7da7b84D426B2e7824D326CDa2C',
  //     19: '0x0a838fc0576c7cef1d2c2cf30472aaee710c396e',
  //   },
  //   token: serializedTokens.bbx,
  //   quoteToken: serializedTokens.chirp,
  // },
  // {
  //   pid: 63,
  //   lpSymbol: 'exUSDT-CHIRP LP',
  //   lpAddresses: {
  //     719: '0xF7a7326F25Afb7da7b84D426B2e7824D326CDa2C',
  //     19: '0x07a1239b6989a0995f692d061c91f4398f5e3cfd',
  //   },
  //   token: serializedTokens.exusdt,
  //   quoteToken: serializedTokens.chirp,
  // },
  // {
  //   pid: 64,
  //   lpSymbol: 'SFIN-CHIRP LP',
  //   lpAddresses: {
  //     719: '0xF7a7326F25Afb7da7b84D426B2e7824D326CDa2C',
  //     19: '0x07a1239b6989a0995f692d061c91f4398f5e3cfd',
  //   },
  //   token: serializedTokens.sfin,
  //   quoteToken: serializedTokens.chirp,
  // },
  // {
  //   pid: 65,
  //   lpSymbol: 'PRO-CHIRP LP',
  //   lpAddresses: {
  //     719: '0xF7a7326F25Afb7da7b84D426B2e7824D326CDa2C',
  //     19: '0x07a1239b6989a0995f692d061c91f4398f5e3cfd',
  //   },
  //   token: serializedTokens.pro,
  //   quoteToken: serializedTokens.chirp,
  // },
  // {
  //   pid: 66,
  //   lpSymbol: 'PSB-CHIRP LP',
  //   lpAddresses: {
  //     719: '0xF7a7326F25Afb7da7b84D426B2e7824D326CDa2C',
  //     19: '0x07a1239b6989a0995f692d061c91f4398f5e3cfd',
  //   },
  //   token: serializedTokens.psb,
  //   quoteToken: serializedTokens.chirp,
  // },
]

export default farms
