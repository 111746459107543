/* eslint-disable @typescript-eslint/no-unused-vars */
import { serializeTokens } from 'utils/serializeTokens'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { bscTestnetTokens, bscTokens } from './tokens'
import { SerializedFarmConfig } from './types'

const serializedTokens = serializeTokens(bscTestnetTokens)
// const { chainId } = useActiveWeb3React()`
export const CAKE_BNB_LP_MAINNET = '0x717Ef9CF2cB13e414Fa567e6070a7737E0CF7C17'

const farms: SerializedFarmConfig[] = [
  /**
   * These 3 farms (PID 0, 2, 3) should always be at the top of the file.
   */
  // {
  //   pid: 0,
  //   lpSymbol: 'FTHR',
  //   lpAddresses: {
  //     719: '0x19eA65E3f8fc8F61743d137B5107172f849d8Eb3',
  //     19: '0x30885515b9AeCc599Dc6D48106B471EAd26dEBB0',
  //   },
  //   token: serializedTokens.cake,
  //   quoteToken: serializedTokens.usdt,
  //   isTokenOnly: true,
  // },
  // {
  //   pid: 1,
  //   lpSymbol: 'FTHR-USDT LP',
  //   lpAddresses: {
  //     719: '0x8bfD8Fd94342ABDc404c3b780AbAf8bcF74f9E51',
  //     19: '0x30885515b9AeCc599Dc6D48106B471EAd26dEBB0',
  //   },
  //   token: serializedTokens.cake,
  //   quoteToken: serializedTokens.usdt,
  // },
  // {
  //   pid: 2,
  //   lpSymbol: 'FTHR-SGB LP',
  //   lpAddresses: {
  //     719: '0xF7a7326F25Afb7da7b84D426B2e7824D326CDa2C',
  //     19: CAKE_BNB_LP_MAINNET,
  //   },
  //   token: serializedTokens.cake,
  //   quoteToken: serializedTokens.wbnb,
  // },
  // {
  //   pid: 3,
  //   lpSymbol: 'USDT-SGB LP',
  //   lpAddresses: {
  //     719: '0xa605851B07a2bb1883687fD2805b46fc218d7D92',
  //     19: '0xA8f8B7C0a4ec1ca9fA115dAe915e33AEDdf2526B',
  //   },
  //   token: serializedTokens.usdt,
  //   quoteToken: serializedTokens.wbnb,
  // },
  // {
  //   pid: 5,
  //   lpSymbol: 'WSGB',
  //   lpAddresses: {
  //     719: '0x5fe8bBD2fD4Ee98b45a996DA2fd22263baD7A597',
  //     19: '0xA8f8B7C0a4ec1ca9fA115dAe915e33AEDdf2526B',
  //   },
  //   token: serializedTokens.wbnb,
  //   quoteToken: serializedTokens.usdt,
  //   isTokenOnly: true,
  // },
]

export default farms
